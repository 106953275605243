import React, { useState, useRef } from "react";
import Loader from "react-js-loader";
import { Link } from "react-router-dom";
import { Box, Button, useDisclosure, Badge, Text, useToast } from "@chakra-ui/react";
import { BiRefresh, BiReset } from "react-icons/bi";
import { FiFilter } from "react-icons/fi";
import { get, round } from "lodash";
import TimezoneFormatter from "../../utils/TimezoneFormatter";
import AddRefundModal from './AddRefundModal'
import TransfiGrid from "../../utils/TransfiGrid";

import { getOrgRoles, getCxRoles } from "../App/useToken";
import RefundFilter from "./RefundFilter";

import moment from "moment";
import FiatRefund from "./FiatRefund";
import CryptoRefund from "./CryptoRefund";
import RefundModal from "./RefundModal";
import RefundModalD24 from "./RefundModalD24";
import CryptoRefundModal from "./CryptoRefundModal";
import { callApiWithToken } from "../../utils/utils";

export default function Refund(props) {
  const [loading, setLoading] = useState(false);
  const [selectedRefundData, setSelectedRefundData] = useState();
  const [selectedRowData, setSelectedRowData] = useState({});
  const [inputData, setInputData] = useState({});
  const [formValues, setFormValues] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const toast = useToast()
  const defaultDate = {
    startDate: moment(new Date()).subtract(2, "months"),
    endDate: moment(new Date()),
  };
  const defaultFilters = {
    dateRange: defaultDate,
  };
  const [filters, setFilters] = useState(defaultFilters);
  const [dateRange, setDateRange] = useState(defaultDate);

  const orgRoles = getOrgRoles();
  const showColumn = orgRoles.includes("transfi_admin");
  const cxRole = getCxRoles();
  const {
    isOpen: isOpenFilterModal,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure({ defaultIsOpen: false });

  const { isOpen: isRefundModalOpen, onOpen: onRefundModalOpen, onClose: onRefundModalClose } = useDisclosure();
    const { isOpen: isRefundModalD24Open, onOpen: onRefundModalD24Open, onClose: onRefundModalD24Close } = useDisclosure();
    const { isOpen: isCryptoRefundModalOpen, onOpen: onCryptoRefundModalOpen, onClose: onCryptoRefundModalClose } = useDisclosure();

    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);

  const gridRef = useRef();

  const refreshGrid = () => {
    gridRef.current.refreshGrid();
  };

  const numberFormatter = (value) => {
    const number = round(value, 2).toFixed(2);
    const result = parseFloat(number).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return result === "NaN" ? "0.00" : result;
  };

  const handleEdit = (row) => {
    setSelectedRowData(row);
    if(row.original.type != "fiat"){
      onCryptoRefundModalOpen()
    }
    if(row?.original?.fiat?.partner === 'D24'){
      onRefundModalD24Open();
    }else{
      onRefundModalOpen();  
    }
};  

const handleCancel = async(row)=>{
  try {
    const response = await callApiWithToken('DELETE',`/api/refund/reject/${row.original.rfId}`)
    if (response?.message) {
      toast({
        title: 'Success',
        description: response.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      refreshGrid();
    } else if (response?.error) {
      toast({
        title: 'Error!',
        description: response.error,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  } catch (err) {
    toast({
      title: 'Error!',
      description: err.message || "Failed to fetch order details.",
      status: 'error',
      duration: 5000,
      isClosable: true,
      position: 'top-right',
    });
  }
}

  const handleRefundData = async (value, row) => {
    const data = [];
    data.push(row?.original);
    setSelectedRefundData(data);
  };

  const statusMap = {
    "on_hold": "Account Details Pending",
    "refund_pending": "Refund Pending",
    "refund_initiated": "Refund Initiated",
    "refund_in_progress": "Refund In Progress",
    "refund_completed": "Refund Completed",
    "refund_failed": "Refund Failed",
    "rejected":"Rejected"
  }

  const columns = [
    {
      Header: "Order Id TransFi",
      accessor: "orderId",
      width: "190",
    },
    {
      Header: "Refund Order Id",
      accessor: "rfId",
      width: "190",
      Cell: ({ value, row }) => {
        return (
          <Link
            style={{ color: "#4ACDED", fontWeight: "bold" }}
            onClick={() => handleRefundData(value, row)}
          >
            {value}
          </Link>
        );
      },
    },
    {
      Header: "Refund Status",
      accessor: "status",
      width: "190",
      Cell :({row})=>{
        const status = row?.original?.status
        return statusMap[status]
      }
    },
    {
      Header: "Refund Currency",
      width: "190",
      Cell: ({ row }) => {
        const currency = row?.original?.fiatRefund?.currency || row?.original?.cryptoRefund?.currency;
        return currency;
      }
    },
    {
      Header: "Fiat/ Crypto delivered to user",
      accessor: "refundedAmount",
      width: "190",
    },
    {
      Header: "Refund Date",
      accessor: "refundDate",
      Cell: ({ row }) => {
        return (
          <TimezoneFormatter
            date={row?.original?.refundDate}
            format={"MM/DD/YYYY"}
          />
        );
      },
    },
    {
      Header: "Refund gain/loss",
      show: showColumn,
      Cell: ({ row }) => {
        const fiatAmount = Number(row?.original?.fiat?.orderAmount || 0);
        const buyRate = Number(get(row.original, "fiat.partnerFee", 0));
        let totalAmt = 0;
        if (row?.original?.fiat?.partnerFee) {
          if (
            row?.original?.fiat?.partnerFee === 0 ||
            row?.original?.fiat?.partnerFee === "NaN"
          ) {
            totalAmt = fiatAmount;
          } else {
            totalAmt = fiatAmount - buyRate;
          }
        } else {
          totalAmt = fiatAmount;
        }
        const partnerFees = Number(
          get(row.original, "fiatRefund.partnerFees", 0)
        );
        const refundedAmount = Number(get(row.original, "refundedAmount", 0));
        const totalAmount = partnerFees + refundedAmount;
        const gain = totalAmt - totalAmount;
        return numberFormatter(gain);
      },
    },
  ];
  if (orgRoles.includes("transfi_admin")) {
    columns.push(
      {
        Header: "Edit",
        show: showColumn,
        Cell: ({ row }) => {
            const status = row?.original?.status;
            return status === "on_hold" ? (
                <Button
                    colorScheme="blue"
                    onClick={() => handleEdit(row)}
                >
                    Edit
                </Button>
            ) : null;
        },
        
    },
    );
  }

  if (orgRoles.includes("transfi_admin")) {
    columns.push(
      {
        Header: "Cancel",
        show: showColumn,
        Cell: ({ row }) => {
            const status = row?.original?.status;
            return status === "on_hold" ? (
                <Button
                    colorScheme="red"
                    onClick={() => handleCancel(row)}
                >
                    Cancel
                </Button>
            ) : null;
        },
        
    },
    );
  }


  const handleOnReset = () => {
    setDateRange(defaultDate);
    setFilters(defaultFilters);
    setFormValues({});
    setInputData({});
  };

  return !loading ? (
    <Box style={{ background: "white" }}>
      {selectedRefundData ? (
        <>
          {selectedRefundData[0]?.type === "fiat" && 
            <FiatRefund
            refundData={selectedRefundData}
            setRefundData={setSelectedRefundData}
            />
           }
          {selectedRefundData[0]?.type === "crypto" && 
            <CryptoRefund
            refundData={selectedRefundData}
            setRefundData={setSelectedRefundData}
            />
           }
        </>
      ) : (
        <>
          <div style={{ float: "right", marginTop: "2%" }}>
            <Button
              leftIcon={<BiRefresh />}
              colorScheme="blue"
              style={{ margin: "0 8px" }}
              size="sm"
              onClick={handleOpen}
            >
              Create Refund
            </Button>
           <AddRefundModal open={modalOpen} onClose={handleClose} refreshGrid={refreshGrid} />
            <Button
              leftIcon={<BiReset style={{ fontSize: "20px" }} />}
              variant={"outline"}
              colorScheme="blue"
              onClick={() => handleOnReset()}
              style={{ margin: "0 8px" }}
              size="sm"
            >
              Reset
            </Button>
            <Button
              leftIcon={<BiRefresh style={{ fontSize: "20px" }} />}
              onClick={() => refreshGrid()}
              variant={"outline"}
              colorScheme="blue"
              style={{ margin: "0 0px" }}
              size="sm"
            >
              Refresh
            </Button>
            <Button
              leftIcon={<FiFilter style={{ fontSize: "20px" }} />}
              onClick={onOpenFilterModal}
              variant={"outline"}
              colorScheme="blue"
              style={{ margin: "0 8px" }}
              size="sm"
            >
              Filter
            </Button>
          </div>
          <TransfiGrid
            ref={gridRef}
            columns={columns}
            dataUrl={"/api/refund/list"}
            filters={{ filters }}
          ></TransfiGrid>
        </>
      )}
      <RefundFilter
        isOpen={isOpenFilterModal}
        onClose={onCloseFilterModal}
        inputData={inputData}
        setInputData={setInputData}
        dateRange={dateRange}
        setDateRange={setDateRange}
        filters={filters}
        setFilters={setFilters}
        formValues={formValues}
        setFormValues={setFormValues}
        handleOnReset={handleOnReset}
      />
      { selectedRowData?.original?.type === 'crypto' ? 
      <CryptoRefundModal isOpen={isCryptoRefundModalOpen} 
      onClose={onCryptoRefundModalClose} 
      onClick={onCryptoRefundModalClose}
      selectedRowData={selectedRowData} 
      refreshGrid={refreshGrid} 
       /> 
       :  selectedRowData?.original?.type === "fiat" && selectedRowData?.original?.fiatRefund?.partner === 'D24' ?
        <RefundModalD24
          isOpen={isRefundModalD24Open}
          onClose={onRefundModalD24Close}
          onClick={onRefundModalD24Close}
          selectedRowData={selectedRowData}
          refreshGrid={refreshGrid}
        /> :
        <RefundModal
          isOpen={isRefundModalOpen}
          onClose={onRefundModalClose}
          onClick={onRefundModalClose}
          selectedRowData={selectedRowData}
          refreshGrid={refreshGrid}
        />
      }
    </Box>
  ) : (
    <Loader
      type="spinner-cub"
      bgColor={"#FFFFFF"}
      title={"Loading..."}
      color={"cyan.400"}
      size={100}
    />
  );
}