import React, { useState, useContext, useRef, useEffect } from "react";
import Loader from "react-js-loader";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import { Badge, useDisclosure } from "@chakra-ui/react";
import { startCase, uniqBy, isEmpty, get, round } from "lodash";
import { Box } from "@chakra-ui/react";

import SplitView from "../../utils/SplitView";
import { NavBarContext } from "../../context/NavBarContext";
import { TimezoneContext } from "../../context/TimezoneContext";
import OrderSpliViewContainer from "./OrderSpliViewContainer";
import OrderFilter from "./helper/OrderFilter";
import { statusMap, colorMap } from "../../utils/config";
import { callApiWithToken } from "../../utils/utils";
import { getOrgRoles } from "../App/useToken";
import TimezoneFormatter from "../../utils/TimezoneFormatter";
import { getCountryData } from "../../utils/getCountries";

export default function GamingOrders() {
    const { orderId } = useParams();
    const [loading, setLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [countryOptions, setCountryOptions] = useState([]);
    const [fiatCurrency, setFiatCurrency] = useState([]);
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [formValues, setFormValues] = useState({});
    const [inputData, setInputData] = useState({});
    const defaultDate = { startDate: moment().subtract(2, "months"), endDate: moment().format("YYYY-MM-DD 23:59:59") };
    const [filters, setFilters] = useState({ dateRange: defaultDate });
    const [dateRange, setDateRange] = useState(defaultDate);
    const USformatter = new Intl.NumberFormat('en-US');

    const roles = getOrgRoles();
    const { selectedOrg } = useContext(NavBarContext);
    const { timezone } = useContext(TimezoneContext);
    const gridRef = useRef();

    const formatPurposeCode = (code) => {
        return code.toLowerCase().replace(/_/g, ' ').replace(/^./, c => c.toUpperCase());
    }

    useEffect(() => {
        getCountryList();
        getPaymentList();
    }, []);

    useEffect(() => {
        refreshGrid();
    }, [timezone]);

    const refreshGrid = () => {
        gridRef.current && gridRef.current.refreshGrid();
    };

    const getCountryList = async () => {
        const { countryList, fiatList } = await getCountryData();
        setCountryOptions(countryList);
        setFiatCurrency(fiatList);
    };

    const getPaymentList = async () => {
        const response = await callApiWithToken("GET", `/api/orders/getPaymentMethods`);
        const data = response.data;
        let list = data.map((country) => {
            return { value: country.name, label: country.name };
        });
        setPaymentOptions(uniqBy(list, (obj) => obj.value));
    };

    const numberFormatter = (value) => {
        const number = round(value, 2).toFixed(2)
        const result = parseFloat(number).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
        return result === "NaN" ? "0.00" : result;
    };

    const columns = [
        {
            Header: "Order Id",
            accessor: "orderId",
            width: "200",
            Cell: ({ value }) => {
                return value;
            },
            zIndex: 1,
            position: 'sticky',
            textAlign: 'center',
        },
        {
            Header: "Customer Order Id",
            accessor: "customer.orderId",
            width: "150",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ value }) => {
                return value || "N/A"
            },
        },
        {
            Header: "Customer Name",
            accessor: "customer.formattedName",
            width: "100",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ value }) => {
                return value || "N/A"
            }
        },
        {
            Header: "Jurisdiction of customer",
            width: "120",
            accessor: "customer.country"
        },
        {
            Header: "Sender Name",
            accessor: "senderName",
            width: "150",
            Cell: ({ value }) => {
                return `${value?.firstName ?? ''} ${value?.lastName ?? ''}`
            }
        },
        {
            Header: "Jurisdiction of sender",
            width: "120",
            accessor: "userCountry"
        },
        {
            Header: "Status",
            accessor: "status",
            width: "130",
            Cell: ({ row }) => {
                const { status, type } = row?.original;
                const newStatus = statusMap[type][status] || status;
                const color = colorMap[type][status] || "blue";
                return <Badge colorScheme={color}> {newStatus} </Badge>;
            },
        },
        {
            Header: "Purpose",
            accessor: "purposeCode",
            width: "150",
            Cell: ({ value }) => {
                return formatPurposeCode(value)
            }
        },
        {
            Header: "Date Order Initiated",
            width: "120",
            accessor: "timestamps",
            Cell: ({ row }) => {
                const { timestamps = {} } = row.original;
                return <TimezoneFormatter date={timestamps?.initiatedAt} format={"MM/DD/YYYY"} />
            },
            color: "info",
        },
        {
            Header: "Time Order Initiated",
            width: "120",
            Cell: ({ row }) => {
                const { timestamps = {} } = row.original;
                return <TimezoneFormatter date={timestamps?.initiatedAt} format={"LT"} />
            },
            color: "info",
        },
        {
            Header: "Date Order Settled",
            width: "120",
            Cell: ({ row }) => {
                const { timestamps = {} } = row.original;
                return <TimezoneFormatter date={timestamps?.fundSettledAt} format={"MM/DD/YYYY"} />
            },
            color: "info",
        },
        {
            Header: "Time Order Settled",
            width: "120",
            Cell: ({ row }) => {
                const { timestamps = {} } = row.original;
                return <TimezoneFormatter date={timestamps?.fundSettledAt} format={"LT"} />
            },
            color: "info",
        },
        {
            Header: "Cut Off Time",
            width: "120",
            Cell: ({ row }) => {
                const { timestamps = {} } = row.original;
                return <TimezoneFormatter date={timestamps?.cutOffAt} format={"MM/DD/YYYY"} />
            },
            color: "info",
        },
        {
            Header: "Settlement Time",
            width: "120",
            Cell: ({ row }) => {
                const { timestamps = {} } = row.original;
                return <TimezoneFormatter date={timestamps?.settlementAt} format={"MM/DD/YYYY"} />
            },
            color: "info",
        },
        {
            Header: "Fiat Payment Partner",
            width: "150",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.partner)) {
                    return row?.original?.fiat?.partner
                } else {
                    return row?.original?.paymentGateway
                }
            }
        },
        {
            Header: "Fiat Partner Order ID",
            accessor: "fiat.orderId",
            width: "150",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
            Cell: ({ value }) => value || "-",
        },
        {
            Header: "Fiat Payment Method",
            width: "150",
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.paymentName)) {
                    return row?.original?.fiat?.paymentName;
                } else {

                    return row?.original?.paymentName;
                }
            }
        },
        {
            Header: "Fiat Currency",
            width: "120",
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.currency)) {
                    return row?.original?.fiat?.currency;
                } else {

                    return row?.original?.fiatTicker;
                }
            }
        },
        {
            Header: "Gross Fiat Amount (Received from the user)",
            width: "230",
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.orderAmount)) {
                    return numberFormatter(row?.original?.fiat?.orderAmount)
                } else {
                    return numberFormatter(row?.original?.fiatAmount)
                }
            }
        },
        {
            Header: "Processing fees %",
            // accessor: "fiat.tfFee",
            width: "120",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                const {fiat = {}} = row.original;
                if(fiat.tfFee && fiat.cxFee){
                    return USformatter.format((parseFloat(fiat.tfFee) + parseFloat(fiat.cxFee)) * 100) + '%'
                }
            },
            color: "warning",
        },
        {
            Header: "Processing fees amt.",
            width: "150",
            Cell: ({ row }) => {
                return row.original?.fees?.processingFee
            },
            color: "warning",
        },
        {
            Header: "Net Amount",
            width: "150",
            color: "warning",
            show: roles.includes("transfi_admin") ? true : false,
            accessor: "fiat.netAmount",
            // Cell: ({ row }) => {
            //     return
            // }

        },
        {
            Header: "Amt. Received In Sub Account - API",
            width: "150",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                if (row?.original?.fiat?.feeSource === "partner") {
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : Number(row?.original?.fiatAmount || 0);
                    const buyRate = Number(get(row.original, "fiat.partnerFee", 0))
                    if (row?.original?.fiat?.partnerFee) {
                        if (row?.original?.fiat?.partnerFee === 0 || row?.original?.fiat?.partnerFee === "NaN") {
                            return numberFormatter(fiatAmount);
                        } else {
                            return numberFormatter(fiatAmount - buyRate);
                        }
                    } else {
                        return numberFormatter(fiatAmount);
                    }
                }
                else {
                    return ("-");
                }

            },
            color: "warning",
        },
        {
            Header: "Amt. Received In Sub Account - Calculated",
            width: "150",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.feeSource)) {
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : Number(row?.original?.fiatAmount || 0);
                    const buyRate = Number(get(row.original, "fiat.partnerFee", 0))
                    if (row?.original?.fiat?.partnerFee) {
                        if (row?.original?.fiat?.partnerFee === 0 || row?.original?.fiat?.partnerFee === "NaN") {
                            return numberFormatter(fiatAmount);
                        } else {
                            return numberFormatter(fiatAmount - buyRate);
                        }
                    } else {
                        return numberFormatter(fiatAmount);
                    }
                } else {
                    return ("-");
                }

            },
            color: "warning",
        },
        {
            Header: "Payment partner fees",
            width: "150",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
            Cell: ({ row }) => {
                if (row?.original?.fiat?.partnerFee) {
                    if (row?.original?.fiat?.partnerFee === 0 || row?.original?.fiat?.partnerFee === "NaN") {
                        return "0.00";
                    } else {
                        return numberFormatter(row?.original?.fiat?.partnerFee);
                    }
                } else {
                    return "0.00";
                }

            }
        },
        {
            Header: "GTV USD",
            width: "150",
            color: "warning",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate)) {
                    return "0.00";
                } else {
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                    const conversionRate = round(Number(row?.original?.fiat?.conversionRate || 0), 6)
                    return numberFormatter(fiatAmount * conversionRate);
                }
            },
        },
        {
            Header: "Amount received in Sub account(USD)",
            width: "180",
            color: "warning",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate)) {
                    return "0.00";
                } else {
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                    const buyRate = Number(row?.original?.fiat?.partnerFee || 0)
                    const subAcc = fiatAmount - buyRate;
                    const conversionRate = Number(row?.original?.fiat?.conversionRate)
                    return numberFormatter(subAcc * conversionRate);
                }
            },
        },
        {
            Header: "Processing fees amt. (USD)",
            width: "180",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate)) {
                    return "0.00";
                } else {
                    const conversionRate = Number(row?.original?.fiat?.conversionRate)
                    if (isEmpty(row?.original?.fiat?.processingFee) && !isEmpty(conversionRate)) {
                        const tfFee = Number(get(row.original, "fiat.tfFee", 0))
                        const cxFee = Number(get(row.original, "fiat.cxFee", 0))
                        return numberFormatter((tfFee + cxFee) * conversionRate);
                    } else {
                        return numberFormatter(row?.original?.fiat?.processingFee * conversionRate);
                    }
                }

            },
            color: "warning",
        },
        {
            Header: "Crypto To Be Sold If Zero Forex Markup",
            width: "200",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
            Cell: ({ row }) => {
                const conversionsRate = Number(row?.original?.fiat?.conversionRate)
                const conversionRate = numberFormatter(1/conversionsRate)
                if (isEmpty(row?.original?.fiat?.processingFee)) {
                    const tfFee = Number(get(row.original, "fiat.tfFee", 0))
                    const cxFee = Number(get(row.original, "fiat.cxFee", 0))
                    const discountRate = Number(get(row.original, "fiat.discount", 0))
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                    return numberFormatter((fiatAmount - (tfFee + cxFee) + discountRate) / conversionRate);
                } else {
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                    const processFees = Number(get(row.original, "fiat.processingFee", 0))
                    const discountRate = Number(get(row.original, "fiat.discount", 0))
                    return numberFormatter((fiatAmount - processFees + discountRate) / conversionRate);
                }
            }
        },
        {
            Header: "FX Markup %",
            width: "100",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
            accessor: "fiat.markupFee",
            Cell: ({ value }) => {
                if(value){
                    return USformatter.format((parseFloat(value)) * 100) + '%'
                }
            }
        },
        {
            Header: "Conversion Rate Charged by TransFi",
            width: "100",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
        },
        {
            Header: "Crypto to be Sold",
            width: "100",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
        },
        {
            Header: "Revenue from Forex",
            width: "100",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
        },
        {
            Header: "Crypto Payment Partner",
            accessor: "crypto.partner",
            width: "170",
            Cell: ({ value }) => startCase(value) || "NA",
            show: roles.includes("transfi_admin") ? true : false,
            color: "info",
        },
        {
            Header: "Crypto Partner Order ID",
            accessor: "crypto.orderId",
            width: "150",
            color: "info",
            Cell: ({ value }) => value || "-",
        },
        {
            Header: "Digital Asset Sent",
            accessor: "crypto.orderAmount",
            width: "150",
            color: "info",
            Cell: ({ value }) => value || "-",
        },
        {
            Header: "Crypto Ticker",
            accessor: "withdrawCurrency",
            width: "150",
            color: "info",
            Cell: ({ value }) => value || "-",
        },
        {
            Header: "Crypto Purchase Rate",
            width: "150",
            accessor: "crypto.conversionRate",
            color: "info",
        },
        {
            Header: "Crypto units delivered - recalculation",
            width: "150",
            color: "info",
        },
        {
            Header: "Crypto conversion fees",
            width: "150",
            color: "info",
        },
        {
            Header: "Gas Fees Actual",
            width: "150",
            color: "info",
        },
        {
            Header: "Net (of gas fees) Crypto paid",
            width: "150",
            color: "info",
        },
        {
            Header: "Gas fees in USD (calculated)",
            width: "150",
            color: "info",
        },
        {
            Header: "Crypto Wallet Address",
            accessor: "crypto.walletAddress",
            width: "150",
            color: "info",
        },
        {
            Header: "Transaction hash",
            accessor: "crypto.transactionHash",
            width: "150",
            color: "info",
        },
    ]

    const condensedColumns = {
        Header: "Order Id",
        accessor: "orderId",
        Cell: ({ row, value }) => {
            const { status, userId, type } = row || {};
            const newStatus = statusMap[type][status] || status;
            const color = colorMap[type][status] || "blue";
            return (
                <>
                    <div>
                        <span color="#4ACDED">{value}</span>
                        <Badge style={{ marginLeft: "20px" }} colorScheme={color}>
                            {" "}
                            {startCase(newStatus)}{" "}
                        </Badge>
                    </div>
                    <div style={{ fontSize: "13px" }}>{userId}</div>
                </>
            );
        },
    };

    const handleOnReset = () => {
        setDateRange(defaultDate);
        setFilters({ dateRange: defaultDate });
        setFormValues({});
        setInputData({});
    };

    return !loading ? (
        <Box style={{ background: "white" }} heading="Orders">
            {orderId ? (
                <></>
            ) : (
                <div style={{ marginTop: "2%" }}>
                    {/* <div style={{ float: "right" }}>
                        <Button
                            leftIcon={<BiRefresh style={{ fontSize: "20px" }} />}
                            onClick={() => refreshGrid()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 0px" }}
                            size="sm"
                        >
                            Refresh
                        </Button>
                    </div>
                    <br /> */}
                </div>
            )}
            <SplitView
                ref={gridRef}
                columns={columns}
                condensed={orderId}
                condensedColumns={condensedColumns}
                dataUrl={"/api/orders/list"}
                condensedUrl={"/gaming-orders"}
                filters={{ impersonatedOrg: selectedOrg, filters, type: "gaming" }}
                downloadReportUrl={"/api/report"}
                collection={"orders"}
                database={"transfi-nucleus"}
                gridFilters={{ "type": "gaming", filters, impersonatedOrg: selectedOrg }}
                headerDetails={{ types: { "createdAt": "date" } }}
                gridName={"Gaming Orders"}
            >
                <OrderSpliViewContainer orderId={orderId} />
            </SplitView>

            <OrderFilter
                onClose={onClose}
                isOpen={isOpen}
                countryOptions={countryOptions}
                fiatCurrency={fiatCurrency}
                paymentOptions={paymentOptions}
                formValues={formValues}
                setFormValues={setFormValues}
                filters={filters}
                setFilters={setFilters}
                dateRange={dateRange}
                setDateRange={setDateRange}
                inputData={inputData}
                setInputData={setInputData}
                handleOnReset={handleOnReset}
                type={"buy"}
            ></OrderFilter>
        </Box>
    ) : (
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={"cyan.400"} size={100} />
    );
}