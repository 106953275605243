import React, { useState, useEffect, useContext } from "react";
import Loader from "react-js-loader";
import { upperCase } from "lodash";
import {
	Box,
	Text
} from "@chakra-ui/react";

import { callApiWithToken } from "../../../utils/utils";
import { EnvTypeContext } from "../../../context/EnvTypeContext";
import HuobiLogo from "./HuobiLogo.svg";
import OKXLogo from "./OKXLogo.png";

export default function PartnerOverview() {

	const [loading, setLoading] = useState(false);
	const [cryptoData, setCryptoData] = useState({});
	const [fiatData, setFiatData] = useState({});
	const [prefundData, setPrefundData] = useState({});
	const [imageData, setImageData] = useState({});
	const [totalAmount, setTotalAmount] = useState({});
	const [customerPrefundBalance, SetCustomerPrefundBalance] = useState([]);

	const { env } = useContext(EnvTypeContext);

	useEffect(() => {
		getPartnersOverview();
	}, [env]);

	const getPartnersOverview = async () => {
		try {
			setLoading(true);
			const response = await callApiWithToken("POST", `/api/admin/finance/partnerOverview`) || {};
			const { status = false, message, data } = response;

			if (status) {
				const { crypto, fiat, cryptoTotalUSD = 0, fiatTotalUSD = 0, imageDetails, prefundAccount, prefundTotalUSD = 0, customer_prefund_balance: prefundCustomerData } = data || {};
				setCryptoData(crypto);
				setFiatData(fiat);
				setImageData(imageDetails);
				setPrefundData(prefundAccount);
				setTotalAmount({ crypto: cryptoTotalUSD, fiat: fiatTotalUSD, prefund: prefundTotalUSD });
				SetCustomerPrefundBalance(prefundCustomerData);
			} else {
				throw new Error(message);
			}
			setLoading(false);
		} catch (e) {
			setLoading(false);
		}
	}

	const LoadImage = ({ partner }) => {
		const { image = "", color = "" } = imageData[partner] || {};
		return <div style={{ background: `${color}`, width: '70px', height: '70px', borderRadius: '50%', display: 'flex', justifyContent: 'center' }}>
			<img style={{ width: '40px', height: '40px', margin: 'auto 0' }} src={image} />
		</div>
	}

	const currencyData = (partner, currencyDetails) => {
		if (partner === "bitpace") {
			return <>
				<div style={{ fontSize: '13px', marginTop: '8px', fontWeight: '500', color: '#2b6cb0' }}><b>{Number(currencyDetails?.EUR).toLocaleString()}</b> EUR </div>
			</>
		}
		if (partner === "bitpace_usd") {
			return <>
				<div style={{ fontSize: '13px', marginTop: '8px', fontWeight: '500', color: '#2b6cb0' }}><b>{Number(currencyDetails?.USD_BALANCE).toLocaleString()}</b> USD </div>
			</>
		}
		if (partner === "nowpay_prefunding" || partner === "coinsph_usdt" || partner === "kucoin") {
			return <>
				<div style={{ fontSize: '13px', marginTop: '8px', fontWeight: '500', color: '#2b6cb0' }}><b>{Number(currencyDetails?.USDT).toLocaleString()}</b> USDT </div>
			</>
		}
		if (partner === "binance_prefunding") {
			return <>
				<div style={{ fontSize: '13px', marginTop: '8px', fontWeight: '500', color: '#2b6cb0' }}><b>{Number(currencyDetails?.IDR).toLocaleString()}</b> IDR , <b>{Number(currencyDetails?.PHP || 0).toLocaleString()}</b> PHP & <b>{Number(currencyDetails?.USDT || 0).toLocaleString()}</b> USDT</div>
			</>
		}
		if (partner === "fuse_prefunding") {
			return <>
				<div style={{ fontSize: '13px', marginTop: '8px', fontWeight: '500', color: '#2b6cb0' }}><b>{Number(currencyDetails?.USDCFUSE).toLocaleString()}</b> USDC & <b>{Number(currencyDetails?.FUSE || 0).toLocaleString()}</b> FUSE</div>
			</>
		}
		if (partner === "safe_wallet_polygon") {
			return <>
				<div style={{ fontSize: '13px', marginTop: '8px', fontWeight: '500', color: '#2b6cb0' }}><b>{Number(currencyDetails?.L3USD).toLocaleString()}</b> L3USD </div>
			</>
		}
		if (partner === "safe_wallet_eth") {
			return <>
				<div style={{ fontSize: '13px', marginTop: '8px', fontWeight: '500', color: '#2b6cb0' }}><b>{Number(currencyDetails?.LIF3).toLocaleString()}</b> LIF3</div>
			</>
		}
		if (partner === "huobi_uab") {
			return <>
				<div style={{ fontSize: '13px', marginTop: '8px', fontWeight: '500', color: '#2b6cb0' }}><b>{Number(currencyDetails?.USDT).toLocaleString()}</b> USDT ,<b>{Number(currencyDetails?.USDC).toLocaleString()} </b> USDC,
					<b>{Number(currencyDetails?.CUSD).toLocaleString()} </b> CUSD & <b>{Number(currencyDetails?.CELO).toLocaleString()} </b> CELO,
				</div>
			</>
		}
		if (partner === "b2c2") {
			return <>
				<div style={{ fontSize: '13px', marginTop: '8px', fontWeight: '500', color: '#2b6cb0' }}><b>{Number(currencyDetails?.USD_BALANCE || 0).toLocaleString()}</b> USD , <b>{Number(currencyDetails?.EUR).toLocaleString()} </b> EUR &
					<b> {Number(currencyDetails?.UST).toLocaleString()} </b> UST
				</div>
			</>
		}
		if (partner === "metamask_eth") {
			return <>
				<div style={{ fontSize: '13px', marginTop: '8px', fontWeight: '500', color: '#2b6cb0' }}><b>{Number(currencyDetails?.ETH || 0).toLocaleString('en-US', { minimumFractionDigits: 4 })}</b> ETH , <b>{Number(currencyDetails?.USDC).toLocaleString()} </b> USDC &
					<b> {Number(currencyDetails?.USDT).toLocaleString()} </b> USDT
				</div>
			</>
		}
		if (partner === "metamask_cusd") {
			return <>
				<div style={{ fontSize: '13px', marginTop: '8px', fontWeight: '500', color: '#2b6cb0' }}>
					<b> {Number(currencyDetails?.CUSD).toLocaleString()} </b> CUSD
				</div>
			</>
		}
		else {
			return (
				<>
					<div style={{ fontSize: '13px', marginTop: '8px', fontWeight: '500', color: '#2b6cb0' }}>
						{currencyDetails?.USDC !== null && currencyDetails?.USDC !== undefined && currencyDetails?.USDC !== '' &&
							<><b>{Number(currencyDetails?.USDC).toLocaleString()}</b> USDC & </>
						}
						<b>{Number(currencyDetails?.USDT || 0).toLocaleString()}</b> USDT
					</div>
				</>
			);

		}
	}

	const CryptoCard = (data) => {
		const { partner, currencyDetails } = data || {}
		return <>
			<div className="balance-card-zoom" style={{ width: '250px', padding: '15px 20px', boxShadow: '0 4px 10px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)', borderRadius: '4px' }}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div>
						<h3 style={{ fontSize: '14px', fontWeight: '600', }}>{upperCase(partner)}</h3>
						<div style={{ fontSize: '26px', marginTop: '8px', fontWeight: '400' }}>$ <b>{Number(currencyDetails?.USD).toLocaleString()}</b></div>
					</div>
					<LoadImage partner={partner} />
				</div>
				{currencyData(partner, currencyDetails)}
			</div>
		</>
	}

	const FiatCard = (data) => {
		const { partner, currencyDetails } = data || {}
		let unknownCurrency = 'USD';
		for (let key in currencyDetails) {
			if (key !== 'USD') {
				unknownCurrency = key;
				break;
			}
		}

		return <>
			<div className="balance-card-zoom" style={{ width: '250px', padding: '15px 20px', boxShadow: '0 4px 10px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)', borderRadius: '4px' }}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div>
						<h3 style={{ fontSize: '14px', fontWeight: '600', }}>{upperCase(partner)}</h3>
						<div style={{ fontSize: '26px', marginTop: '8px', fontWeight: '400' }}>$ <b>{Number(currencyDetails?.USD).toLocaleString()}</b></div>
					</div>
				</div>
				<div style={{ fontSize: '13px', marginTop: '10px', fontWeight: '500', color: '#2b6cb0' }}><b>{Number(currencyDetails[unknownCurrency]).toLocaleString()}</b>  {unknownCurrency}</div>
			</div>
		</>
	}

	const PrefundCard = (data) => {
		const { partner, currencyDetails } = data || {}
		return <>
			<div className="balance-card-zoom" style={{ width: '250px', padding: '15px 20px', boxShadow: '0 4px 10px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)', borderRadius: '4px' }}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div>
						<h3 style={{ fontSize: '14px', fontWeight: '600', }}>{upperCase(partner)}</h3>
						<div style={{ fontSize: '26px', marginTop: '8px', fontWeight: '400' }}>$ <b>{Number(currencyDetails?.USD).toLocaleString()}</b></div>
					</div>
				</div>
				{currencyData(partner, currencyDetails)}
			</div>
		</>
	}

	const CustomerBalanceCard = ({ data }) => {
		const { name, balances } = data || {}
		return <>
			<div className="balance-card-zoom" style={{ width: '250px', padding: '15px 20px', boxShadow: '0 4px 10px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)', borderRadius: '4px', marginBottom: '40px' }}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div>
						<h3 style={{ fontSize: '14px', fontWeight: '600', }}>{name || "-"}</h3>
						<div style={{ fontSize: '26px', marginTop: '8px', fontWeight: '400' }}>$ <b>{"yo"}</b></div>
					</div>
				</div>
				{balances.map(singlebalance => {
					const { availableBalance = 0 } = singlebalance?.balance || {};
					const { currency } = singlebalance;
					return (
						<div style={{ fontSize: '13px', marginTop: '10px', fontWeight: '500', color: '#2b6cb0' }}>
							<b>{Number(availableBalance).toLocaleString()}</b>  {currency}
						</div>
					)
				})}
			</div>
		</>
	}

	const renderFiatCards = () => {
		const fiatCards = Object.keys(fiatData).sort();

		return <div style={{ display: 'flex', justifyContent: 'left', padding: '20px 10px', flexWrap: 'wrap', gap: '4.45%' }}>
			{fiatCards.map(value => {
				return <div style={{ marginBottom: '40px' }}><FiatCard partner={value} currencyDetails={fiatData[value]} /></div>
			})}
		</div>
	}

	const renderPrefundCards = () => {
		const PrefundCards = Object.keys(prefundData).sort();

		return <div style={{ display: 'flex', justifyContent: 'left', padding: '20px 10px', flexWrap: 'wrap', gap: '4.45%' }}>
			{PrefundCards.map(value => {
				return <div style={{ marginBottom: '40px' }}><PrefundCard partner={value} currencyDetails={prefundData[value]} /></div>
			})}
		</div>
	}

	const renderCryptoCards = () => {
		const cryptoCards = Object.keys(cryptoData).sort();
		return <div style={{ display: 'flex', justifyContent: 'left', padding: '20px 10px', flexWrap: 'wrap', gap: '4.45%' }}>
			{cryptoCards.map(value => {
				return <div style={{ marginBottom: '40px' }}><CryptoCard partner={value} currencyDetails={cryptoData[value]} /></div>
			})}
		</div>
	}

	return !loading ? (
		<>
			<Box w="100%" p={4}>
				<div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '20px', padding: '0 10px', marginBottom: '5px' }}>
					<Text fontWeight={500}>Liquidity Partners</Text>
					<Text fontWeight={500}>Total Assets - <span style={{ fontWeight: '400' }}>{`USD ${totalAmount?.crypto?.toLocaleString()}`}</span></Text>
				</div>
				<hr style={{ margin: '0 8px', marginBottom: '16px' }} />
				{renderCryptoCards()}
			</Box>
			<Box w="100%" p={4}>
				<div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '20px', padding: '0 10px', marginBottom: '5px' }}>
					<Text fontWeight={500}>Payment Partners</Text>
					<Text fontWeight={500}>Total Funds - <span style={{ fontWeight: '400' }}>{`USD ${totalAmount?.fiat?.toLocaleString()}`}</span></Text>
				</div>
				<hr style={{ margin: '0 8px', marginBottom: '16px' }} />
				{renderFiatCards()}
			</Box>
			<Box w="100%" p={4}>
				<div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '20px', padding: '0 10px', marginBottom: '5px' }}>
					<Text fontWeight={500}>Prefunding Accounts</Text>
					<Text fontWeight={500}>Total Funds - <span style={{ fontWeight: '400' }}>{`USD ${totalAmount?.prefund?.toLocaleString()}`}</span></Text>
				</div>
				<hr style={{ margin: '0 8px', marginBottom: '16px' }} />
				{renderPrefundCards()}
			</Box>
			<Box w="100%" p={4}>
				<div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '20px', padding: '0 10px', marginBottom: '5px' }}>
					<Text fontWeight={500}>Customer Prefund Balance</Text>
				</div>
				<hr style={{ margin: '0 8px', marginBottom: '16px' }} />
				<div style={{ display: 'flex', justifyContent: 'left', padding: '20px 10px', flexWrap: 'wrap', gap: '4.45%' }}>
					{customerPrefundBalance.map(data => <CustomerBalanceCard data={data} />)}
				</div>
				{/* {renderPrefundCards()} */}
			</Box>
		</>
	) : (
		<Loader
			type="spinner-cub"
			bgColor={"#FFFFFF"}
			title={"Loading..."}
			color={"cyan.400"}
			size={100}
		/>
	);
};
