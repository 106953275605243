import { Badge, Box, Button, HStack, Stack, Text, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { BiReset } from 'react-icons/bi';
import { IoAddCircleSharp } from "react-icons/io5";
import moment from 'moment';
import { round, startCase } from 'lodash';

import Loader from '../../utils/Loader';
import { callApiWithTokenV2 } from '../../utils/utils';
import Grid from '../../utils/agTable';
import { constants } from "../../constants";
import CollectionsSettlementModal from './helper/CollectionsSettlementModal';
import { NavBarContext } from '../../context/NavBarContext';
import TimezoneFormatter from "../../utils/TimezoneFormatter";

const CollectionsOrders = () => {
  const { colorMap, statusMap } = constants;

  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState([])
  const [select, setSelect] = useState(false);
  const [refreshGrid, setRefreshGrid] = useState(false);

  const { isOpen: settlementIsOpen, onOpen: settlementOnOpen, onClose: settlementOnClose } = useDisclosure()

  const { selectedOrg } = useContext(NavBarContext);

  const toast = useToast();

  useEffect(() => {
    setRefreshGrid(!refreshGrid);
  }, [selectedOrg])

  const USformatter = new Intl.NumberFormat('en-US');

  const getOrderData = async (filters) => {
    const responseData = await callApiWithTokenV2.post(`/api/orders/tfpay/list?`, { impersonatedOrg: selectedOrg }, {
      params: {
        filters
      }
    })
    return responseData;
  }
  const handleReset = () => {
    setRefreshGrid(!refreshGrid);
    setSelectedData([]);
  };
  var filterParams = {
    // provide comparator function
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      if (cellValue === null) return -1;
      let cellDate = new Date(cellValue);
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    browserDatePicker: true
  }

  const columns = [
    {
      headerName: "Order ID TransFi",
      field: "orderId",
      getQuickFilterText: params => {
        return params.value.name;
      },
      width: '250',
      cellRenderer: ({ value }) => {
        return <p style={{ color: "#1A4FD6", fontWeight: "bold", fontSize: '14px' }} >{value}</p>;
      },
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Order ID Client", field: "", cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Order Status",
      field: "status",
      enableRowGroup: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      cellRenderer: ({ value }) => {
        const newStatus = statusMap[value] || value;
        const color = colorMap[value];
        return <Badge width={"100%"} textAlign={"center"} colorScheme={color}>{newStatus}</Badge>;
      },
    },
    {
      headerName: "Settlement type", field: "", cellRenderer: ({ value }) => (
        <Text>{value ? value : 'Deferred'}</Text>
      )
    },
    {
      headerName: "Customer Name With Jurisdiction", field: "customerNameWithJurisdiction", cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Settlement Status",
      field: "settlementStatus",
      enableRowGroup: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      cellRenderer: ({ value }) => {
        if (value === 'settled') {
          return <Badge width={"100%"} textAlign={"center"} colorScheme={'green'}>{value}</Badge>;
        } else if (value === 'unsettled') {
          return <Badge width={"100%"} textAlign={"center"} colorScheme={'red'}>Unsettled</Badge>;
        } else {
          return <Badge width={"100%"} textAlign={"center"} colorScheme={'red'}>Unsettled</Badge>;
        }
      },
    },
    {
      headerName: "Customer Email", field: "customer.email", enableRowGroup: true, customerVisibility: true, width: '300', cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Sender Name", field: "senderName.firstName", customerVisibility: true, width: '200', cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Sender Email", field: "email", enableRowGroup: true, customerVisibility: true, width: '200', cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Purpose of Transaction", field: "purposeCode", enableRowGroup: true, customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Customer Type", field: "customer.customerType", enableRowGroup: true, customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'Organization'}</Text>
      )
    },
    {
      headerName: "Jurisdiction of Sender", field: "userCountry", enableRowGroup: true, customerVisibility: true, width: '200', cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Jurisdiction of Recipient", field: "customer.country", enableRowGroup: true, customerVisibility: false, width: '200', cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Date & Time Invoice Initiated",
      field: "timestamps.initiatedAt",
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      enableRowGroup: true,
      cellDataType: 'date',
      width: '220',
      cellRenderer: ({ value }) => (
        <Text>{value ? <TimezoneFormatter date={value} format={"DD-MMM-YYYY @ h:mm A"} /> : 'N/A'}</Text>
      ),
    },
    {
      headerName: "Date & Time Invoice Paid",
      field: "timestamps.fundSettledAt",
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      enableRowGroup: true,
      cellDataType: 'date',
      width: '220',
      cellRenderer: ({ value }) => (
        <Text>{value ? <TimezoneFormatter date={value} format={"DD-MMM-YYYY @ h:mm A"} /> : 'N/A'}</Text>
      ),
    },
    {
      headerName: "Cut off Time",
      field: "timestamps.cutOffAt",
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      enableRowGroup: true,
      cellDataType: 'date',
      width: '220',
      cellRenderer: ({ value }) => (
        <Text>{value ? <TimezoneFormatter date={value} format={"DD-MMM-YYYY @ h:mm A"} /> : 'N/A'}</Text>
      ),
    },
    {
      headerName: "Settlement Time",
      field: "timestamps.settlementSettledAt",
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      enableRowGroup: true,
      cellDataType: 'date',
      width: '220',
      cellRenderer: ({ value }) => (
        <Text>{value ? <TimezoneFormatter date={value} format={"DD-MMM-YYYY @ h:mm A"} /> : 'N/A'}</Text>
      ),
    },
    {
      headerName: "Fiat Payment Partner", field: "paymentGateway", customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Fiat Payment Partner Order ID", field: "fiat.orderId", customerVisibility: false, width: '200', cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Fiat Payment Method", field: "paymentName", customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Fiat Currency", field: "depositCurrency", customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Gross Fiat Amount(Received from the user)",
      field: "depositAmount",
      customerVisibility: true,
      cellDataType: 'number',
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Processing Fees %",
      field: "fiat.tfFee",
      customerVisibility: true,
      aggFunc: 'avg',
      filter: 'agNumberColumnFilter',
      cellDataType: 'number',
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value * 100) + '%' : 'N/A'}</Text>
      )
    },
    {
      headerName: "Processing Fees Amount",
      field: "fiat.processingFee",
      customerVisibility: true,
      cellDataType: 'number',
      aggFunc: 'sum',
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Net Amount",
      field: "netAmount",
      customerVisibility: true,
      cellDataType: 'number',
      aggFunc: 'sum',
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Amount Received in Sub-Account",
      field: "",
      customerVisibility: true,
      cellDataType: 'number',
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Amount Received in Sub-Account - Calculated",
      field: "amountReceivedInSubAccount",
      customerVisibility: true,
      cellDataType: 'number',
      aggFunc: 'sum',
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Payment Partner Fee",
      field: "fiat.partnerFee",
      customerVisibility: true,
      cellDataType: 'number',
      aggFunc: 'sum',
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Sub-Account Receipt Date",
      field: "",
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      enableRowGroup: true,
      cellDataType: 'date',
      width: '220',
      cellRenderer: ({ value }) => (
        <Text>{value ? <TimezoneFormatter date={value} format={"DD-MMM-YYYY @ h:mm A"} /> : 'N/A'}</Text>
      ),
    },
    {
      headerName: "Conversion rate to USD",
      field: "fiat.conversionRateInUsd",
      customerVisibility: true,
      cellDataType: 'number',
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "GTV",
      field: "gtv",
      customerVisibility: true,
      cellDataType: 'number',
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Amount Received In Sub Account in USD",
      field: "amountReceivedInSubAccountUsd",
      customerVisibility: true,
      cellDataType: 'number',
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Processing Fees Amount in USD",
      field: "processingFeeAmmountInUsd",
      customerVisibility: true,
      cellDataType: 'number',
      aggFunc: 'sum',
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Payment Partner Fee in USD",
      field: "partnerFeeInUsd",
      customerVisibility: true,
      cellDataType: 'number',
      aggFunc: 'sum',
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Crypto Sold if Zero Forex Markup",
      field: "cryptoSoldOnZeroForex",
      customerVisibility: true,
      cellDataType: 'number',
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "FX Markup %",
      field: "fiat.markupFee",
      cellDataType: 'number',
      customerVisibility: false,
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(Math.abs(value) * 100) + "%" : '0'}</Text>
      )
    },
    {
      headerName: "Conversion Rate Charged by TransFi",
      field: "conversionRateUsed",
      filter: 'agNumberColumnFilter',
      cellDataType: 'number',
      customerVisibility: false,
      cellRenderer: ({ value }) => (
        <Text>{value ? round(value, 6) : '0'}</Text>
      )
    },
    {
      headerName: "Crypto to be Sold",
      field: "cryptoToBeSold",
      filter: 'agNumberColumnFilter',
      cellDataType: 'number',
      customerVisibility: false,
      cellRenderer: ({ value }) => (
        <Text>{value ? round(value, 6) : '0'}</Text>
      )
    },
    {
      headerName: "Revenue from Forex",
      field: "revenueFromForex",
      filter: 'agNumberColumnFilter',
      cellDataType: 'number',
      customerVisibility: false,
      cellRenderer: ({ value }) => (
        <Text>{value ? round(value, 6) : '0'}</Text>
      )
    },
    {
      headerName: "Crypto Payment Partner", field: "crypto.partner", customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Crypto Partner Order ID", field: "crypto.orderId", customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Digital Asset Sent",
      field: "crypto.orderAmount",
      cellDataType: 'number',
      customerVisibility: true,
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Crypto Ticker", field: "withdrawCurrency", customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value}</Text>
      )
    },
    {
      headerName: "Crypto Purchase Rate", field: "", customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>1</Text>
      )
    },
    {
      headerName: "Crypto units delivered - recalculation", field: "cryptoToBeSold", customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? round(value, 6) : '0'}</Text>
      )
    },
    {
      headerName: "Gas Fees Actual", field: "", customerVisibility: false, filter: 'agNumberColumnFilter', cellDataType: 'number', cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Crypto conversion fees", field: "", customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value || 'N/A'}</Text>
      )
    },
    {
      headerName: "Net (of gas fees) Crypto paid ", field: "", customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value || 'N/A'}</Text>
      )
    },
    {
      headerName: "Gas fees in USD (calculated) ", field: "", customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value || 'N/A'}</Text>
      )
    },

    {
      headerName: "Crypto Wallet Address", field: "crypto.walletAddress", customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? value : "N/A"}</Text>
      )
    },
    {
      headerName: "Transaction hash", field: "crypto.transactionHash", customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? value : "N/A"}</Text>
      )
    },
  ]

  const handleCreateSettlement = () => {
    setSelect(true);
    settlementOnOpen()
  };

  return (
    <Stack
      direction={"column"}
      justifyContent={"space-between"}
      flexGrow={1}
      p={5}
    >
      <Box >
        <HStack pb={5} justifyContent={"flex-end"}>
          <Button
            onClick={handleCreateSettlement}
            leftIcon={<IoAddCircleSharp style={{ fontSize: "20px" }} />}
            color="#1A4FD6"
            border={'1px'}
            borderColor={"#1A4FD6"}
            _hover={{ bg: "#1A4FD6", textColor: "white" }}
          >
            Create Settlement
          </Button>
          <Button
            leftIcon={<BiReset style={{ fontSize: "20px" }} />}
            color={"#525C76"}
            variant={"outline"}
            borderColor={"#d2d2d2"}
            _hover={{ bg: "#ffffff" }}
            onClick={handleReset}
          >
            Reset
          </Button>

        </HStack>
        {!isLoading ?
          <Grid columns={columns} key={refreshGrid.toString()} getOrderData={getOrderData} setSelectedData={setSelectedData} select={select} type="collections" selectedData={selectedData} />

          : <Loader />}
      </Box>
      <CollectionsSettlementModal isOpen={settlementIsOpen} onClose={settlementOnClose} data={selectedData} setSelect={setSelect} handleReset={handleReset} />
    </Stack>
  );
};

export default CollectionsOrders;