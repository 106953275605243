const urlMap = {
    "localhost:3000": {
        env: "local",
        server: "http://localhost:8000",
        widget: "https://dev-buy.transfi.com",
        sandboxWidget: "https://sandbox-buy.transfi.com",
    },
    "localhost:3001": {
        env: "local",
        server: "http://localhost:8000",
        widget: "https://dev-buy.transfi.com",
        sandboxWidget: "https://sandbox-buy.transfi.com",
    },
    "localhost:3002": {
        env: "local",
        server: "http://localhost:8000",
        widget: "https://dev-buy.transfi.com",
        sandboxWidget: "https://sandbox-buy.transfi.com",
    },
    "dev-dashboard.transfi.com": {
        env: "development",
        server: "https://dev-dashboard-server.transfi.com",
        widget: "https://dev-buy.transfi.com",
        sandboxWidget: "https://sandbox-buy.transfi.com",
    },
    "dashboard.transfi.com": {
        env: "production",
        server: "https://dashboard-server.transfi.com",
        widget: "https://buy.transfi.com",
        sandboxWidget: "https://sandbox-buy.transfi.com",
    },
    "tfcdntest.azurewebsites.net": {
        env: "development",
        server: "https://servcsportal.azurewebsites.net",
    },
    "cxss.transfi.com": {
        env: "local",
        server: "http://localhost:8000",
    },
   "qa-dashboard.transfi.com": {
        env: "development",
        server: "https://qa-dashboard-server.transfi.com",
        widget: "https://qa-buy.transfi.com",
        sandboxWidget: "https://sandbox-buy.transfi.com",
    }
};

const statusMap = {
    buy: {
        initiated: "Onramp Initiated",
        manual_review: "Onramp Manual Review",
        fund_processing: "Onramp Fiat Processing",
        fund_settled: "Onramp Crypto Initiated",
        fund_failed: "Onramp Fiat Not Received",
        asset_deposited: "Onramp Crypto Initiated",
        asset_settled: "Onramp Successful",
        asset_failed: "Onramp Crypto Initiated",
        asset_deposit_failed: "Onramp Crypto Initiated",
        expired: "Onramp Expired",
        asset_settle_failed: "Onramp Crypto Initiated",
        draft: "Onramp draft",
        cancelled: "Onramp cancelled",
        on_hold:"Onramp OnHold"
    },
    sell: {
        initiated: "Offramp Initiated",
        manual_review: "Offramp Manual Review",
        asset_settled: "Offramp Fiat Initiated",
        asset_mismatch: "Crypto Mismatch",
        fund_processing: "Offramp Fiat Initiated",
        fund_settled: "Offramp Successful",
        fund_failed: "Offramp Failed",
        refund_processed: "Crypto Refund Processed",
        refund_completed: "Crypto Refunded",
        refund_initiated: "Crypto Refund Initiated",
        expired: "Offramp Expired",
        cancelled: "Offramp Cancelled",
        on_hold:"Offramp OnHold"
    },
    otc_payin: {
        initiated: "Onramp Initiated",
        fund_settled: "Onramp Fiat Received",
        asset_deposited: "Onramp Crypto Initiated",
        asset_settled: "Onramp Successful",
        asset_deposit_failed: "Onramp Rejected",
    },
    otc_payout: {
        initiated: "Offramp Initiated",
        asset_settled: "Offramp Crypto Recieved",
        fund_processing: "Offramp Approved",
        fund_settled: "Offramp Successful",
        fund_failed: "Offramp Rejected",
    },
    gaming: {
        initiated: "Initiated",
        asset_deposited: "Asset Deposited",
        fund_settled: "Fund Settled",
        expired: "Expired",
        fund_failed: "Fund Failed",
        fund_processing: "Fund Processing",
        completed: "Completed",
    }
};

const statusFilterConfigs = {
    "Onramp Initiated": ["initiated"],
    "Onramp Manual Review": ["manual_review"],
    "Offramp OnHold":["on_hold"],
    "Onramp Fiat Processing": ["fund_processing"],
    "Onramp Crypto Initiated": ["fund_settled", "asset_deposited", "asset_failed", "asset_deposit_failed", "asset_settle_failed"],
    "Onramp Fiat Not Received": ["fund_failed"],
    "Onramp Successful": ["asset_settled"],
    "Onramp Expired": ["expired"],
    "Offramp Initiated": ["initiated"],
    "Offramp Fiat Initiated": ["fund_processing", "asset_settled"],
    "Offramp Failed": ["fund_failed"],
    "Crypto Mismatch": ["asset_mismatch"],
    "Offramp Successful": ["fund_settled"],
    "Offramp Crypto Refunded": ["refund_processed", "refund_completed", "refund_initiated"],
    "Offramp Expired": ["expired"],
    "Offramp Cancelled": ["cancelled"],
    "Onramp Fiat Received": ["fund_settled"],
    "Onramp Approved": ["asset_deposited"],
    "Onramp Rejected": ["asset_deposit_failed"],
    "Offramp Crypto Recieved": ["asset_settled"],
    "Offramp Approved": ["fund_processing"],
    "Offramp Rejected": ["fund_failed"],
    "Offramp Manual Review": ["manual_review"],
};

const colorMap = {
    buy: {
        initiated: "purple",
        on_hold: "orange",
        fund_processing: "orange",
        fund_settled: "orange",
        asset_settled: "green",
        fund_failed: "purple",
        asset_deposited: "green",
        asset_failed: "red",
        asset_deposit_failed: "red",
        asset_settle_failed: "red",
        expired: "red",
        draft: "orange",
        cancelled: "red"
    },
    sell: {
        initiated: "purple",
        fund_processing: "orange",
        fund_settled: "green",
        fund_failed: "red",
        asset_settled: "orange",
        asset_failed: "purple",
        asset_mismatch: "teal",
        refund_initiated: "purple",
        refund_processed: "purple",
        refund_completed: "green",
        expired: "red",
        cancelled: "red",
    },
    otc_payout: {
        initiated: "purple",
        fund_processing: "orange",
        fund_settled: "green",
        fund_failed: "purple",
        asset_settled: "orange",
        asset_failed: "purple",
        asset_mismatch: "teal",
        refund_initiated: "purple",
        refund_processed: "purple",
        refund_completed: "green",
        expired: "red",
        cancelled: "red",
        asset_deposited: "green",
        asset_deposit_failed: "red",
    },
    otc_payin: {
        initiated: "purple",
        fund_processing: "orange",
        fund_settled: "orange",
        asset_settled: "green",
        fund_failed: "purple",
        asset_deposited: "green",
        asset_failed: "red",
        asset_deposit_failed: "red",
        asset_settle_failed: "red",
        expired: "red",
    },
    gaming: {
        initiated: "purple",
        asset_deposited: "green",
        fund_settled: "orange",
        expired: "red",
        fund_failed: "purple",
        fund_processing: "orange",
        completed: "green",
    }
};

const refundColorMap = {
    initiated: "purple",
    approved: "purple",
    refund_processing: "orange",
    refund_completed: "green",
    refund_failed: "red",
};

const refundStatusMap = {
    initiated: "Refund Initiated",
    approved: "Refund Approved",
    refund_processing: "Refund Processing",
    refund_completed: "Refund Completed",
    refund_failed: "Refund Failed",
}

export { urlMap, statusMap, colorMap, statusFilterConfigs, refundColorMap, refundStatusMap  };
