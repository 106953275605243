import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  useToast,
  chakra,
  Stack,
} from "@chakra-ui/react";
import { callApiWithToken } from "../../utils/utils";

const RefundModal = ({ isOpen, onClose, selectedRowData, refreshGrid }) => {
  const toast = useToast();
  const [formValues, setFormValues] = useState({
    bankName: '',
    bankAccountNumber: '',
    bankAccountType: '',
    bankBranch: '',
    ifscNumber: '',
    swiftCode: '',
    bankBranchNumber: '',
    userName: '',
    refundAmount: '',
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const amount =( selectedRowData?.original?.type === 'fiat' || selectedRowData?.original?.type === 'refund') ? selectedRowData?.original?.fiatRefund?.amount : selectedRowData?.original?.cryptoRefund?.amount;
    setFormValues((prev) => ({ ...prev, refundAmount: amount }));
  }, [selectedRowData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const validate = () => {
    const newErrors = {};
    if (!formValues.bankName) newErrors.bankName = "Bank Name is required";
    if (!formValues.bankAccountNumber) newErrors.bankAccountNumber = "Bank Account Number is required";
    if (!formValues.userName) newErrors.userName = "Bank Account Holder Name is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) return;

    try {
      const response = await callApiWithToken("POST", "/api/refund/editAccountDetails", {
        refundAmount: formValues?.refundAmount,
        bankName: formValues.bankName,
        bankAccountNumber: formValues.bankAccountNumber,
        bankAccountType: formValues.bankAccountType,
        bankBranch: formValues.bankBranch,
        ifscNumber: formValues.ifscNumber,
        swiftCode: formValues.swiftCode,
        bankBranchNumber: formValues.bankBranchNumber,
        userName: formValues.userName,
        currency: selectedRowData?.original?.fiatTicker,
        orderId: selectedRowData?.original?.orderId,
        userId: selectedRowData?.original?.userId,
        partner: selectedRowData?.original?.fiat?.partner,
      });

      if (response?.message) {
        toast({
          title: 'Success',
          description: response.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        refreshGrid();
        onClose();
      } else if (response?.error) {
        toast({
          title: 'Error!',
          description: response.error,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } catch (err) {
      toast({
        title: 'Error!',
        description: err.message || "Failed to fetch order details.",
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }

    setFormValues({
      bankName: '',
      bankAccountNumber: '',
      bankAccountType: '',
      bankBranch: '',
      ifscNumber: '',
      swiftCode: '',
      bankBranchNumber: '',
      userName: '',
      refundAmount: '',
    });
  };

  const handleClose = ()=>{
    onClose()
    setErrors({})
  }

  return (
    <chakra.form>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">Account Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <FormControl isInvalid={Boolean(errors.refundAmount)}>
                <FormLabel htmlFor="refundAmount">Refund Amount</FormLabel>
                <Input
                  id="refundAmount"
                  name="refundAmount"
                  type="text"
                  placeholder="Refund Amount"
                  value={formValues.refundAmount}
                  isDisabled 
                />
                <FormErrorMessage>{errors.refundAmount}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(errors.bankName)}>
                <FormLabel htmlFor="bankName">Bank Name</FormLabel>
                <Input
                  id="bankName"
                  name="bankName"
                  type="text"
                  placeholder="Enter the bank name"
                  value={formValues.bankName}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors.bankName}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(errors.bankAccountNumber)}>
                <FormLabel htmlFor="bankAccountNumber">Bank Account Number</FormLabel>
                <Input
                  id="bankAccountNumber"
                  name="bankAccountNumber"
                  type="text"
                  placeholder="Enter the bank account number"
                  value={formValues.bankAccountNumber}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors.bankAccountNumber}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(errors.bankAccountType)}>
                <FormLabel htmlFor="bankAccountType">Bank Account Type</FormLabel>
                <Input
                  id="bankAccountType"
                  name="bankAccountType"
                  type="text"
                  placeholder="Enter the bank account type"
                  value={formValues.bankAccountType}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors.bankAccountType}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(errors.bankBranch)}>
                <FormLabel htmlFor="bankBranch">Bank Branch</FormLabel>
                <Input
                  id="bankBranch"
                  name="bankBranch"
                  type="text"
                  placeholder="Enter the bank branch"
                  value={formValues.bankBranch}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors.bankBranch}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(errors.ifscNumber)}>
                <FormLabel htmlFor="ifscNumber">IFSC Number</FormLabel>
                <Input
                  id="ifscNumber"
                  name="ifscNumber"
                  type="text"
                  placeholder="Enter the IFSC number"
                  value={formValues.ifscNumber}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors.ifscNumber}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(errors.swiftCode)}>
                <FormLabel htmlFor="swiftCode">SWIFT Code</FormLabel>
                <Input
                  id="swiftCode"
                  name="swiftCode"
                  type="text"
                  placeholder="Enter the SWIFT code"
                  value={formValues.swiftCode}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors.swiftCode}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(errors.bankBranchNumber)}>
                <FormLabel htmlFor="bankBranchNumber">Bank Branch Number</FormLabel>
                <Input
                  id="bankBranchNumber"
                  name="bankBranchNumber"
                  type="text"
                  placeholder="Enter the bank branch number"
                  value={formValues.bankBranchNumber}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors.bankBranchNumber}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(errors.userName)}>
                <FormLabel htmlFor="userName">Bank Account Holder Name</FormLabel>
                <Input
                  id="userName"
                  name="userName"
                  type="text"
                  placeholder="Enter the name of the user"
                  value={formValues.userName}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors.userName}</FormErrorMessage>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button onClick={handleSubmit} colorScheme="blue" mr={3} type="submit">
              Initiate
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </chakra.form>
  );
};

export default RefundModal;
