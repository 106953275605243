import React, { useState } from 'react';
import Loader from "react-js-loader";
import { Tab, TabList, Tabs, TabPanels, TabPanel } from '@chakra-ui/react';

import OrderDetails from '../Orders/helper/OrderDetails';
import TransactionDetails from '../Orders/helper/TransactionDetails';
import CryptoMonitoringDetails from './helper/CryptoMonitoringDetails';
import { getOrgRoles } from '../App/useToken';
import NameCheckLogs from './helper/NameCheckLogs';
import EmailCheckLogs from './helper/EmailCheckLogs';

export default function OrderSpliViewContainer(props) {
    const { orderId, email } = props || {};
    const [loading, setLoading] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const orgRoles = getOrgRoles();

    return !loading ? <>
        <Tabs colorScheme='cyan'>
            <TabList p={2} style={{ justifyContent: 'center' }}>
                <Tab>Details</Tab>
                <Tab>Transactions</Tab>
                <Tab>Crypto Monitoring</Tab>
                {orgRoles.includes("transfi_admin") && <Tab>Name Check Logs</Tab>  }
                {orgRoles.includes("transfi_admin") && <Tab>Email Check Logs</Tab>  }
            </TabList>
            <TabPanels>
                <TabPanel>
                    <OrderDetails orderId={orderId} orderData={orderData} setOrderData={setOrderData} />
                </TabPanel>
                <TabPanel>
                    <TransactionDetails orderId={orderId} />
                </TabPanel>
                <TabPanel>
                    <CryptoMonitoringDetails orderId={orderId} />
                </TabPanel>
                {orgRoles.includes("transfi_admin") && 
                <TabPanel>
                    <NameCheckLogs orderId={orderId} />
                </TabPanel>}
                {orgRoles.includes("transfi_admin") && 
                <TabPanel>
                    <EmailCheckLogs email={email}/>
                </TabPanel>}
            </TabPanels>
        </Tabs>
    </> :
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={'#FFFFFF'} size={100} />
}
