import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
  useToast,
  Input,
} from "@chakra-ui/react";
import { callApiWithToken } from "../../utils/utils";

const AddRefundModal = ({ open, onClose, refreshGrid }) => {
  const [orderId, setOrderId] = useState("");
  const [refundReason, setRefundReason] = useState("");
  const [errors, setErrors] = useState({});
  const toast = useToast();

  const validateForm = () => {
    const newErrors = {};
    if (!orderId) newErrors.orderId = "Order ID is required.";
    if (!refundReason) newErrors.refundReason = "Refund reason is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const refundData = {
      orderId: orderId.trim(),
      errorCode: refundReason,
    };

    try {
      const response = await callApiWithToken(
        "POST",
        "/api/refund/v2/create",
        refundData
      );

      if (response?.code === "success") {
        toast({
          title: "Success",
          description: response.message,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        refreshGrid();
      } else if (response?.code === "failed") {
        toast({
          title: "Error",
          description: response.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An unexpected error occurred. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setOrderId("");
      setRefundReason("");
      onClose();
    }
  };

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Initiate Refund</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <FormControl isRequired mb={4} isInvalid={!!errors.orderId}>
              <FormLabel>Order ID</FormLabel>
              <Input
                value={orderId}
                onChange={(e) => {
                  setOrderId(e.target.value);
                  setErrors((prev) => ({ ...prev, orderId: "" }));
                }}
                placeholder="Enter Order ID"
              />
              {errors.orderId && (
                <FormErrorMessage>{errors.orderId}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isRequired mb={4} isInvalid={!!errors.refundReason}>
              <FormLabel>Refund Reason</FormLabel>
              <Select
                value={refundReason}
                onChange={(e) => {
                  setRefundReason(e.target.value);
                  setErrors((prev) => ({ ...prev, refundReason: "" }));
                }}
                placeholder="Select a reason"
              >
                {" "}
                {/* <option value="no_reason">No Reason</option> */}
                <option value="crypto_partner_not_available">
                  Crypto Partner Not Available
                </option>
                <option value="minimum_withdrawal_issue">
                  Minimum Withdrawal Issue
                </option>
                <option value="order_total_less_than_10_usdt">
                  Order Total Less Than 10 USDT
                </option>
                <option value="name_mismatch">Name Mismatch</option>
                <option value="incorrect_account_details">
                  Incorrect Account Details
                </option>
              </Select>
              {errors.refundReason && (
                <FormErrorMessage>{errors.refundReason}</FormErrorMessage>
              )}
            </FormControl>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} mr={3}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddRefundModal;