import React, { useEffect, useState } from 'react';
import { callApiWithToken } from '../../../utils/utils';
import { Box, Text, VStack, Heading } from "@chakra-ui/react";

const EmailCheckLogs = (props) => {
  const { email } = props || {};
  const [emailValidationLogs, setEmailValidationLogs] = useState();

  useEffect(() => {
    fetchEmailCheckValidationLogs();
  }, []);

  const fetchEmailCheckValidationLogs = async () => {
    try {
      const responseData = await callApiWithToken(
        "GET",
        `/api/orders/getEmailValidationLogs?email=${email}`
      );
      setEmailValidationLogs(responseData?.emailValidationLogs);
    } catch (error) {
      console.error("Error fetching email check validation logs:", error);
    }
  };

  const renderData = (obj, level = 0) => {
    if (!obj || typeof obj !== 'object') return null;

    // Define the keys to skip in the rendering
    const keysToSkip = ['_id', '__v','created_at', 'createdAt'];

    return Object.keys(obj).map((key) => {
      if (keysToSkip.includes(key)) return null;  // Skip certain keys
      const value = obj[key];

      if (key === 'response') {
        return renderData(value, level); // Render its contents without incrementing the level (no heading)
      }

      return typeof value === 'object' && value !== null ? (
        <Box pl={level * 4} key={key} mb={2}>
          <Heading size="sm" mb={1}>{key}</Heading>
          {renderData(value, level + 1)}
        </Box>
      ) : (
        <Box pl={level * 4} key={key} mb={1}>
          <Text>
          <span style={{fontWeight: '500'}}>{key}: </span>{value !== null && value !== undefined ? value.toString() : 'N/A'}
          </Text>
        </Box>
      );
    });
  };

  return (
      <Box 
        width={'100%'}
        maxHeight={'500px'}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        bg={'gray.100'}
        boxShadow="lg"
        p={4}
        overflowY={'auto'}
      >
            {emailValidationLogs ? (
              <VStack align="stretch" spacing={4}>
                {renderData(emailValidationLogs)}
              </VStack>
            ) : (
              <Text color={'gray'}>Logs not available.</Text>
            )}
      </Box>
  );
}

export default EmailCheckLogs;