import React, { useEffect, useState } from 'react';
import { Box, Center, Stat, StatLabel, } from "@chakra-ui/react";
import { callApiWithToken } from '../../../utils/utils';
import { useParams } from 'react-router';

const NameCheckLogs = (props) => {
  const [loading, setLoading] = useState(false);
  const [nameValidationLogs, setNameValidationLogs] = useState({});
  const { userId } = useParams();

  useEffect(()=>{
    fetchNameCheckValidationLogs();
  },[])

  const fetchNameCheckValidationLogs = async () => {
    try{
      const responseData = await callApiWithToken(
        "GET",
        `/api/orders/getNameCheckValidationLogs?entityId=${userId}`
      );
      setNameValidationLogs(responseData?.nameCheckValidationLogs);
    } catch (error) {
      console.error("Error fetching name check validation logs:", error);
    }
  }

  return (
    <Center>
        <Box
        width={'100%'}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        bg={'gray.100'}
        boxShadow="lg"
        p={4}
      >
         {[
      { label: 'Name From Partner', value: nameValidationLogs?.nameFromPartner },
      { label: 'Partner', value: nameValidationLogs?.partner },
      { label: 'Score', value: nameValidationLogs?.score },
      { label: 'Status', value: nameValidationLogs?.status },
    ].map(({ label, value }) => (
      <div style={{ display: 'inline-flex', width: '100%' }}>
                <div style={{ float: 'left', width: '50%' }}>
                    <Stat float={'left'}>
                        <StatLabel>{label}</StatLabel>
                    </Stat>
                </div>
                <div style={{ float: 'right', width: '50%', marginRight: '10px' }}>
                    <Stat float={'right'}>
                        <StatLabel><b>{value ?? 'N/A'}</b></StatLabel>
                    </Stat>
                </div>
            </div>
    ))}
      </Box>
    </Center>
  )
}

export default NameCheckLogs