import React, { useState } from 'react';
import Loader from "react-js-loader";
import { Tab, TabList, Tabs, TabPanels, TabPanel } from '@chakra-ui/react';

import OrderDetails from './helper/OrderDetails';
import UserDetails from './helper/UserDetails';
import Comment from './helper/Comment';
import { getOrgRoles } from '../App/useToken';
import EmailCheckLogs from './helper/EmailCheckLogs';
import NameCheckLogs from './helper/NameCheckLogs';

export default function UserSpliViewContainer(props) {
    const { userId, email } = props || {};
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState([]);
    const orgRoles = getOrgRoles();

    return !loading ? <>
        <Tabs colorScheme='cyan'>
            <TabList p={2} style={{ justifyContent: 'center' }}>
                <Tab>Details</Tab>
                <Tab>Orders</Tab>
                <Tab>Comments</Tab>
                {orgRoles.includes("transfi_admin") && <Tab>Name Check Logs</Tab>  }
                {orgRoles.includes("transfi_admin") && <Tab>Email Check Logs</Tab>  }
            </TabList>
            <TabPanels>
                <TabPanel>
                    <UserDetails userId={userId} userDetails={userDetails} setUserDetails={setUserDetails} />
                </TabPanel>
                <TabPanel>
                    <OrderDetails userId={userId} />
                </TabPanel>
                <TabPanel>
                    <Comment userId={userId} />
                </TabPanel>
                {orgRoles.includes("transfi_admin") && 
                <TabPanel>
                    <NameCheckLogs userId={userId} />
                </TabPanel>}
                {orgRoles.includes("transfi_admin") && 
                <TabPanel>
                    <EmailCheckLogs email={email}/>
                </TabPanel>}
            </TabPanels>
        </Tabs>
    </> :
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={'#FFFFFF'} size={100} />
}
